<template>
    <div>
        <router-link to="/e-commerce/price-range/new">
            <vs-button
              class="
                btn-add-new
                p-3
                mb-4
                ml-4
                rounded-lg
                cursor-pointer
                flex
                items-center
                justify-center
              "
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">إضافة تحديد معين جديد</span>
            </vs-button>
          </router-link>
      <table class="table">
        <thead>
          <tr>
            <th>السعر من</th>
            <th>السعر الى</th>
            <th>نسبة الزيادة</th>
            <th>العمليات</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(priceRange, index) in priceRanges.data.data" :key="index">
            <td>{{ priceRange.price_from }}</td>
            <td>{{ priceRange.price_to }}</td>
            <td>{{ priceRange.selling_price }}%</td>
            <td>
              <button class="btn btn-secondary" @click="editPriceRange(priceRange)">Edit</button>
              <button class="btn btn-danger" @click="deletePriceRange(priceRange.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        priceRanges: [],
      };
    },
    created() {
      this.getPriceRanges();
    },
    methods: {
      getPriceRanges() {
        axios.get('https://boutiquette-store.ly/price-ranges')
          .then(response => {
            this.priceRanges = response.data;
          })
          .catch(error => {
            null
          });
      },
      addPriceRange() {
        // Code to add a new price range
      },
      editPriceRange(priceRange) {
        // Code to edit a price range
      },
      deletePriceRange(id) {
        axios.delete(`https://boutiquette-store.ly/price-ranges/${id}`)
          .then(response => {
            this.getPriceRanges();
          })
          .catch(error => {
            null
          });
      },
    },
  };
  </script>
  